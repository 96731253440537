//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainTitle from "@/components/MainTitle.vue";
import MainDescription from "@/components/MainDescription.vue";
import AppTabs from "@/components/AppTabs.vue";
import AppSelect from "@/components/AppSelect.vue";
import EntitySearch from "@/components/EntitySearch.vue";
import LoadingSpinner from "@/components/Loading/Spinner";
import QueriesSelectionTable from "@/components/Seo/QueriesSelectionTable.vue";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import ModalMinusWords from "../components/modals/ModalMinusWords.vue";
import Tooltip from "../components/Tooltip.vue";
import { getMarketplacesListArray } from "@/utils/enums";
import { dateRangeFromDate, today } from "@/utils/dates";
// import { useRoute } from "vue-router";

export default {
  name: "QueriesSelection",
  components: {
    MainTitle,
    MainDescription,
    AppTabs,
    AppSelect,
    EntitySearch,
    LoadingSpinner,
    QueriesSelectionTable,
    DataTableActions,
    ModalMinusWords,
    Tooltip
  },
  meta: {
    title: "Подбор запросов"
  },
  // setup() {
  //     const route = useRoute();

  //     onMounted(() => {
  //         const mp = route.params.mp;
  //         const skuList = route.params.skuList;
  //         const queriesList = route.params.queriesList;
  //         const categorySearchId = route.params.categorySearchId;
  //     });
  // },
  data() {
    const mp = this.$route.params.mp;
    // const skuList = this.$route.query.skuList.split(",");
    // const queriesList = this.$route.query.queriesList.split(",");
    const searchCategoryValueQuery = this.$route.query.searchCategoryValue;
    const categoryIdQuery = this.$route.query.categoryId;
    const searchSkuValueQuery = this.$route.query.searchSkuValue;
    const searchQueriesValueQuery = this.$route.query.searchQueriesValue;
    //console.log(mp);
    //console.log(searchSkuValueQuery);
    //console.log(searchQueriesValueQuery);
    //console.log(searchCategoryValueQuery);
    //console.log(categoryIdQuery);
    const tabs = [{
      id: 2,
      title: "По товарам"
    }, {
      id: 3,
      title: "По запросам"
    }, {
      id: 1,
      title: "По категориям"
    }];
    let tabs_model = tabs[0];
    let searchCategoryValue = "";
    let categoryId = "";
    let searchSkuValue = "";
    let searchQueriesValue = "";
    if (searchCategoryValueQuery) {
      tabs_model = tabs[2];
      searchCategoryValue = searchCategoryValueQuery;
      categoryId = categoryIdQuery;
    }
    if (searchQueriesValueQuery) {
      tabs_model = tabs[1];
      searchQueriesValue = searchQueriesValueQuery;
    }
    if (searchSkuValueQuery) {
      tabs_model = tabs[0];
      searchSkuValue = searchSkuValueQuery;
    }
    const allSearchCategoryExamples = {
      wb: ["Костюм спортивный", "https://www.wildberries.ru/catalog/detyam/odezhda/dlya-malchikov"],
      ozon: ["Костюм спортивный", "https://www.ozon.ru/category/odezhda-dlya-malchikov-7605/"]
    };
    const allSearchSkuExamples = {
      wb: ["79690323", "https://www.wildberries.ru/catalog/79690323/detail.aspx"],
      ozon: ["149563258", "https://www.ozon.ru/product/nabor-stolovoy-posudy-luminarc-okean-luminarc-iz-45-predm-kolichestvo-person-6-149563258/?sh=Cs30Hqa98w"]
    };
    const allSearchQueriesExamples = {
      wb: ["Костюм спортивный"],
      ozon: ["Костюм спортивный"]
    };
    const select_marketplace_items = [...getMarketplacesListArray()];
    let selectedMarketplace = select_marketplace_items[0];
    if (mp === "ozon") {
      selectedMarketplace = select_marketplace_items[1];
    }
    let searchExamples = allSearchCategoryExamples.wb;
    if (mp === "ozon") {
      searchExamples = allSearchCategoryExamples.ozon;
    }
    let searchSkuExamples = allSearchSkuExamples.wb;
    if (mp === "ozon") {
      searchSkuExamples = allSearchSkuExamples.ozon;
    }
    let searchQueriesExamples = allSearchQueriesExamples.wb;
    if (mp === "ozon") {
      searchQueriesExamples = allSearchQueriesExamples.ozon;
    }
    return {
      select_marketplace_items,
      selectedMarketplace,
      tabs,
      tabs_model,
      allSearchCategoryExamples,
      allSearchSkuExamples,
      allSearchQueriesExamples,
      searchExamples,
      searchSkuExamples,
      searchQueriesExamples,
      searchCategoryValue,
      searchSkuValue,
      searchQueriesValue,
      marketplace: "",
      categoryId,
      categorySearchId: 0,
      skuList: [],
      queriesList: [],
      minusWords: []
    };
  },
  mounted() {
    if (this.$route.params.mp) {
      this.search();
    }
  },
  methods: {
    onMinusWordsAdded(words) {
      this.minusWords = words;
    },
    openMinusWordsModal() {
      this.$refs.ModalMinusWords.open();
    },
    getTableId() {
      return this._uid;
    },
    async searchLoader(query) {
      const result = await this.$store.dispatch("category/getCategoryByName", {
        query,
        mp: this.selectedMarketplace.id
      });
      this.categoryId = result[0].c_id;
      return result;
    },
    search() {
      this.$access.hasOrThrow("queriesSelection");
      if (this.tabs_model.id === 1) {
        this.categorySearchId = this.categoryId;
      }
      if (this.tabs_model.id === 2) {
        this.skuList = this.skuArray;
      }
      if (this.tabs_model.id === 3) {
        this.queriesList = this.queriesArray;
      }
      this.scrollToTable();
    },
    scrollToTable() {
      const pos = document.getElementById("table").offsetTop;
      const time = 500;
      const currentPos = window.pageYOffset;
      let start = null;
      window.requestAnimationFrame(function step(currentTime) {
        start = !start ? currentTime : start;
        var progress = currentTime - start;
        if (currentPos < pos) {
          window.scrollTo(0, (pos - currentPos) * progress / time + currentPos);
        } else {
          window.scrollTo(0, currentPos - (currentPos - pos) * progress / time);
        }
        if (progress < time) {
          window.requestAnimationFrame(step);
        } else {
          window.scrollTo(0, pos);
        }
      });
    },
    searchCategoryValueChange(value) {
      this.searchCategoryValue = value;
    },
    searchSkuValueChange(value) {
      this.searchSkuValue = value;
    },
    searchQueriesValueChange(value) {
      this.searchQueriesValue = value;
    },
    onSelectSearchCategoryValue(value) {
      this.categoryId = value.c_id;
      this.searchCategoryValue = value.c_name;
    }
  },
  computed: {
    disabled() {
      if (this.tabs_model.id === 1) {
        return this.categoryId === 0;
      }
      if (this.tabs_model.id === 2) {
        return !this.skuArray.length > 0;
      }
      if (this.tabs_model.id === 3) {
        return !this.queriesArray.length > 0;
      }
      return true;
    },
    skuArray() {
      return this.searchSkuValue.split("\n").filter(item => item !== "");
    },
    queriesArray() {
      return this.searchQueriesValue.split("\n").filter(item => item !== "");
    },
    reportSettings() {
      const monthRange = dateRangeFromDate(today(), 30);
      const dates = monthRange.map(item => {
        const split = item.split("-");
        return split[1] + "-" + split[2] + "-" + split[0];
      });
      const reportSettings = {
        negative_keywords: this.minusWords,
        date_from: dates[0],
        date_to: dates[1],
        datasource: this.selectedMarketplace.id
      };
      if (this.tabs_model.id === 1) {
        reportSettings.category_id = this.categorySearchId;
      }
      if (this.tabs_model.id === 2) {
        reportSettings.sku = this.skuList;
      }
      if (this.tabs_model.id === 3) {
        reportSettings.query = this.queriesList;
      }
      return reportSettings;
    }
  },
  watch: {
    selectedMarketplace(value) {
      this.searchCategoryValue = "";
      this.searchSkuValue = "";
      this.searchQueriesValue = "";
      this.categoryId = 0;
      this.categorySearchId = 0;
      this.skuList = [];
      this.queriesList = [];
      if (value.id === "wb") {
        this.searchExamples = this.allSearchCategoryExamples.wb;
        this.searchSkuExamples = this.allSearchSkuExamples.wb;
        this.searchQueriesExamples = this.allSearchQueriesExamples.wb;
      }
      if (value.id === "ozon") {
        this.searchExamples = this.allSearchCategoryExamples.ozon;
        this.searchSkuExamples = this.allSearchSkuExamples.ozon;
        this.searchQueriesExamples = this.allSearchQueriesExamples.ozon;
      }
    }
    // categoryId(value) {
    //     //console.log(value);
    // },
  }
};